<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="addQuestionnaire" ref="form">
        <v-card>
          <v-card-title class="text-h5">Add Questionnaire</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Name"
                    hide-details="auto"
                    v-model="form.name"
                    :error-messages="form.$getError('name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    outlined
                    required
                    label="Question Type"
                    hide-details="auto"
                    v-model="form.question_type"
                    :items="questionTypes"
                    :error-messages="form.$getError('question_type')"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Note"
                    v-model="form.note"
                    :error-messages="form.$getError('note')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    outlined
                    required
                    label="Is Popup Message Next?"
                    hide-details="auto"
                    v-model="form.is_popup_next"
                    :error-messages="form.$getError('is_popup_next')"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Popup Message"
                    hide-details="auto"
                    v-show="form.is_popup_next"
                    v-model="form.popup_message"
                    :error-messages="form.$getError('popup_message')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    outlined
                    required
                    label="Questionnaire Category Label"
                    hide-details="auto"
                    v-model="form.question_category_id"
                    @input="getLabelDetail"
                    :items="questionnaireCategories"
                    :error-messages="form.$getError('question_category_id')"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    outlined
                    required
                    label="Have subtext fields?"
                    hide-details="auto"
                    v-model="subtextFields"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    outlined
                    required
                    v-show="subtextFields"
                    v-model="form.subtext.list"
                    :items="subtextListItems"
                    :search-input.sync="searchSubtextList"
                    hide-selected
                    hint="Maximum of 5 tags"
                    label="Subtext List"
                    multiple
                    persistent-hint
                    small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ search }}</strong
                            >". Press <kbd>enter</kbd> or <kbd>tab</kbd> to
                            create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Subtext Message"
                    hide-details="auto"
                    v-show="subtextFields"
                    v-model="form.subtext.message"
                    :error-messages="form.$getError('subtext.message')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" right>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text color="green" v-bind="attrs" @click="snackbar.show = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import Questionnaire from '@/models/Questionnaire'
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiPlus, mdiTrashCanOutline } from '@mdi/js'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icons: {
        plus: mdiPlus,
        delete: mdiTrashCanOutline
      },
      dialog: this.value,
      subtextFields: false,
      form: new Form({
        name: null,
        choices: [
          {
            value: null,
            description: null
          }
        ],
        question_type: null,
        is_popup_next: false,
        popup_message: null,
        label: null,
        subtext: {
          list: [],
          message: null
        },
        question_category_id: null,
        note: null
      }),
      items: ['Yes', 'No'],
      options: {
        itemsPerPage: 100,
        sortBy: []
      },
      search: null,
      searchSubtextList: null,
      subtextListItems: [],
      questionTypes: [
        { text: 'Multiple Choice', value: 'multiple_choice' },
        { text: 'True or False', value: 'true_or_false' },
        { text: 'Open Ended', value: 'open_ended' },
        { text: 'Identification', value: 'identification' }
      ],
      activePicker: null,
      date: null,
      snackbar: {
        show: false,
        message: null
      }
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    ...mapActions({
      getCategories: 'questionnaireCategory/getCategories'
    }),
    ...mapMutations({
      clearCategories: 'questionnaireCategory/clearCategoryList'
    }),
    async getDataFromApi() {
      this.loading = true
      this.clearCategories()
      await this.getCategories({
        search: this.search,
        ...this.options
      })
      this.loading = false
    },
    addQuestionnaire() {
      this.form.$busy = true
      this.form.$clearErrors()
      new Questionnaire({
        creator_id: this.creator.id,
        ...this.form.$data()
      })
        .save()
        .then(res => {
          this.$router.replace(`onboarding/${res.id}`)
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },
    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.$clearErrors()
    },
    getLabelDetail(evt) {
      const selectedLabel = this.questionnaireCategories.find(
        category => category.value === evt
      )
      this.form.label = selectedLabel.text
    },
    showSnackbar(message) {
      this.snackbar.message = message
      this.snackbar.show = true
    },
    addChoiceForm() {
      this.form.choices.push({
        value: '',
        description: ''
      })
    },
    removeChoiceForm(index) {
      this.form.choices.splice(index, 1)
    }
  },
  computed: {
    ...mapState({
      creator: state => state.auth.user,
      questionnaireCategories: state =>
        state.questionnaireCategory.list.map(list => ({
          text: list.name,
          value: list.id
        }))
    })
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  }
}
</script>
