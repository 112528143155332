<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Questionnaires ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="mb-2"
        @click="addQuestionnaireDialog = true"
      >
        <v-icon left> {{ icons.add }} </v-icon>Add Questionnaire
      </v-btn>
    </v-app-bar>

    <div class="mt-12 mb-6">
      <v-row>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            outlined
            clearable
            hide-details
            label="Search"
            @input="doFilter"
            placeholder="Enter name or question type"
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-select
            outlined
            hide-details
            @change="doFilter"
            :items="questionnaireCategories"
            label="Filter by Category"
            v-model="filters.category"
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :items="questionnaires"
      :loading="loading"
      :headers="headers"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="listMeta.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[10, 20, 50, 100]"
        />
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:[`item.choices`]="{ item }">
        <v-chip
          v-for="(choice, index) in item.choices"
          :key="index"
          class="ma-1"
        >
          {{ choice }}
        </v-chip>
      </template>
      <template v-slot:[`item.question_type`]="{ item }">
        {{ getQuestionnaireType(item.question_type) }}
      </template>
      <template v-slot:[`item.questionnaire_category`]="{ item }">
        {{ item.questionnaire_category }}
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          block
          depressed
          @click="
            $router.push({
              name: 'questionnaires.onboarding.details',
              params: { id: item.id }
            })
          "
        >
          Edit
        </v-btn>
      </template>
    </v-data-table>

    <AddQuestionnaireDialog
      v-model="addQuestionnaireDialog"
      @event="getDataFromApi"
    ></AddQuestionnaireDialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiFileExcel, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AddQuestionnaireDialog from './components/AddQuestionnaireDialog.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'CategoriesPage',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    AddQuestionnaireDialog
  },
  data() {
    return {
      options: {},
      loading: false,
      search: null,
      addQuestionnaireDialog: false,
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          width: '30%'
        },
        {
          text: 'Choices',
          sortable: false,
          value: 'choices',
          width: '20%'
        },
        {
          text: 'Type',
          sortable: true,
          value: 'question_type',
          width: '15%',
          align: 'center'
        },
        {
          text: 'Category',
          sortable: false,
          value: 'questionnaire_category',
          width: '10%',
          align: 'center'
        },
        {
          text: 'Date Created',
          sortable: true,
          value: 'created_at',
          width: '20%',
          align: 'center'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions',
          width: '5%',
          align: 'center'
        }
      ],
      filters: {
        category: null
      },
      icons: {
        export: mdiFileExcel,
        add: mdiPlus
      },
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      includes: ['questionnaireCategory'],
      questionTypes: [
        {
          value: 'identification',
          text: 'Identification'
        },
        {
          value: 'multiple_choice',
          text: 'Multiple Choice'
        },
        {
          value: 'open_ended',
          text: 'Open Ended'
        },
        {
          value: 'true_or_false',
          text: 'True or False'
        }
      ]
    }
  },

  computed: {
    ...mapState({
      questionnaires: state =>
        state.questionnaires.list.map(questionnaire => ({
          ...questionnaire,
          choices:
            questionnaire.choices &&
            questionnaire.choices.map(
              choice =>
                `${choice.value}${
                  choice.description ? ` - ${choice.description}` : ''
                }`
            ),
          questionnaire_category:
            questionnaire.questionnaire_category &&
            questionnaire.questionnaire_category[0].name,
          created_at: questionnaire.addedDate
        })),
      listMeta: state => state.questionnaires.listMeta,
      questionnaireCategories: state => {
        const categories = state.questionnaireCategory.list.map(category => ({
          text: category.name,
          value: category.name
        }))
        categories.unshift({
          text: 'All',
          value: null
        })
        return categories
      }
    })
  },

  methods: {
    ...mapActions({
      getQuestionnaires: 'questionnaires/getQuestionnaires'
    }),

    ...mapMutations({
      clearQuestionnaires: 'questionnaires/clearQuestionnaireList'
    }),

    doFilter: debounce(function() {
      this.options.page = 1
      this.getDataFromApi()
    }, 600),

    async getDataFromApi() {
      this.loading = true
      this.clearQuestionnaires()
      await this.getQuestionnaires({
        search: this.search,
        filters: this.filters,
        includes: this.includes,
        ...this.options
      })
      this.loading = false
    },

    getQuestionnaireType(value) {
      const questionType = this.questionTypes.find(
        questionType => questionType.value === value
      )

      return questionType ? questionType.text : value
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  }
}
</script>
